import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import withVertical from '@clearscore-group/ui.external-cs-auth.vertical';
import useRouteProps from '@clearscore/hooks.use-route-props';
import isInternalUrl from '@clearscore/helpers.is-internal-url';

const VerticalRedirect = () => {
    const { redirectUrl = '/' } = useRouteProps();
    const history = useHistory();

    const isInternal = isInternalUrl(redirectUrl) && !redirectUrl.includes('www.clearscore.com');

    useEffect(() => {
        if (isInternal) return;

        history.replace('/');
        window.location.assign(redirectUrl);
    }, []);

    return isInternal ? <Redirect to={redirectUrl} /> : null;
};

export default withVertical(VerticalRedirect, {
    name: 'vertical-redirect',
});
